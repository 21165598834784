import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './transition.scss';
import App from './App';
import qoreContext from './qoreContext';


if (window.self !== window.top) {
    document.body.innerHTML = '<h1>Access Denied</h1>';
    document.body.style.color = 'red';
    document.body.style.textAlign = 'center';
    document.body.style.marginTop = '20%';
    // throw new Error('This page cannot be loaded in an iframe.');
} else {
    ReactDOM.render(
        <qoreContext.context.Provider
            value={{
                client: qoreContext.client
            }}
        >
            <App/>
        </qoreContext.context.Provider>
    , document.getElementById('root'));
}
